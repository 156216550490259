<template>
  <div data-v-e7e518dc="" class="c-login-202208191618">
    <video autoplay muted loop class="bg_video" :poster="poster">
      <source src="../../assets/banner/bg.mp4" type="video/mp4"/>
    </video>
<!--    <div data-v-e7e518dc="" class="login-container">-->
<!--      <i data-v-e7e518dc="" class="left-pic">-->
<!--      </i>-->
<!--      <p data-v-e7e518dc="" class="tip">-->
<!--        欢迎登录黄骅市产业发展服务平台-->
<!--      </p>-->
<!--      <div data-v-e7e518dc="" class="login_body">-->
<!--        <div data-v-e7e518dc="" class="el-tabs el-tabs&#45;&#45;top">-->
<!--          <div class="el-tabs__header is-top">-->
<!--            <div class="el-tabs__nav-wrap is-top">-->
<!--              <div class="el-tabs__nav-scroll">-->
<!--                <div role="tablist" class="el-tabs__nav is-top" style="transform: translateX(0px);">-->
<!--&lt;!&ndash;                  <div class="el-tabs__active-bar is-top" style="width: 200px;" :style="{ transform: transformSize }">&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div id="tab-personal" aria-controls="pane-personal" role="tab" aria-selected="true"&ndash;&gt;-->
<!--&lt;!&ndash;                       tabindex="0" class="el-tabs__item is-top" @click="tabclick(0)" :class="{'is-active':tab==0}">&ndash;&gt;-->
<!--&lt;!&ndash;                    个人登录&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                  <div id="tab-enterprise" aria-controls="pane-enterprise"   role="tab" tabindex="-1"-->
<!--                       class="el-tabs__item is-top">-->
<!--                    企业登录-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="el-tabs__content" v-if="tab==0">-->
<!--            <div data-v-e7e518dc="" role="tabpanel"  aria-labelledby="tab-personal"-->
<!--                 class="el-tab-pane">-->
<!--              <form data-v-e7e518dc="" class="el-form login-form" v-if="!iszhuce">-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="text" v-model="phone" autocomplete="off" placeholder="请输入您的手机号" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="password" v-model="password" autocomplete="off" placeholder="请输入登陆密码" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="findmima" @click="findmima">忘记密码?</div>-->
<!--                &lt;!&ndash;                <div data-v-e7e518dc="" class="el-form-item is-required">&ndash;&gt;-->
<!--                &lt;!&ndash;                  <div class="el-form-item__content" style="margin-left: 0px;">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <div data-v-e7e518dc="" class="code_wrap">&ndash;&gt;-->
<!--                &lt;!&ndash;                      <div data-v-e7e518dc="" class="el-input" style="width: 45%;">&ndash;&gt;-->
<!--                &lt;!&ndash;                        <input type="text" autocomplete="off" placeholder="请输入验证码" class="el-input__inner">&ndash;&gt;-->
<!--                &lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                &lt;!&ndash;                      <button data-v-e7e518dc="" type="button" class="el-button el-button&#45;&#45;default"&ndash;&gt;-->
<!--                &lt;!&ndash;                              style="width: 30%;">&ndash;&gt;-->
<!--                &lt;!&ndash;                        <span>&ndash;&gt;-->
<!--                &lt;!&ndash;												获取验证码&ndash;&gt;-->
<!--                &lt;!&ndash;											</span>&ndash;&gt;-->
<!--                &lt;!&ndash;                      </button>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                &lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div data-v-e7e518dc="" class="el-form-item">-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;line-height:20px">-->
<!--                    <el-checkbox v-model="bol" style="display: inline-block"></el-checkbox>-->
<!--                    <span data-v-e7e518dc="" class="aggrement">-->
<!--												我同意黄骅市产业发展服务平台使用我所提交的信息用于快捷登录或者创建账号。查看-->
<!--												<span data-v-e7e518dc="">-->
<!--													《用户服务协议》-->
<!--												</span>-->
<!--												与-->
<!--												<span data-v-e7e518dc="">-->
<!--													《个人信息保护政策》-->
<!--												</span>-->
<!--											</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <button data-v-e7e518dc="" @click="login1" type="button" class="el-button login_btn el-button&#45;&#45;primary">-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  <span>-->
<!--									登录-->
<!--								</span>-->
<!--                </button>-->
<!--              </form>-->
<!--              <form data-v-e7e518dc="" class="el-form login-form" style="min-width: 358px" v-else>-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="text" v-model="phone" autocomplete="off" placeholder="请输入您的手机号" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="text" v-model="creditCode" autocomplete="off" placeholder="请输入统一社会信用代码" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="text" v-model="email" autocomplete="off" placeholder="请输入您的邮箱" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="password" v-model="password" autocomplete="off" placeholder="请输入登陆密码" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="password" v-model="password1" autocomplete="off" placeholder="请确认登陆密码" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <button data-v-e7e518dc="" @click="zclogin" type="button" class="el-button login_btn el-button&#45;&#45;primary">-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  <span>-->
<!--									提交-->
<!--								</span>-->
<!--                </button>-->
<!--              </form>-->
<!--              <div data-v-e7e518dc="" class="divider_wrap el-divider el-divider&#45;&#45;horizontal">-->
<!--                <div data-v-e7e518dc="" class="el-divider__text is-center" @click="zhuce">-->
<!--                  {{!iszhuce?'注册':'返回'}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div data-v-e7e518dc="" role="tabpanel" aria-hidden="true" id="pane-enterprise"-->
<!--                 aria-labelledby="tab-enterprise" class="el-tab-pane" style="display: none;">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="el-tabs__content" v-else>-->
<!--            <div data-v-e7e518dc="" role="tabpanel" aria-labelledby="tab-personal"-->
<!--                 class="el-tab-pane">-->
<!--              <form data-v-e7e518dc="" class="el-form login-form"   v-if="ewm">-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="text" v-model="phone" autocomplete="off" placeholder="请输入您的账号" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div data-v-e7e518dc="" class="el-form-item is-required">-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;">-->
<!--                    <div  class="el-input">-->
<!--                      <input type="password" v-model="password" autocomplete="off" placeholder="请输入登陆密码" class="el-input__inner">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div data-v-e7e518dc="" class="el-form-item">-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  <div class="el-form-item__content" style="margin-left: 0px;line-height:20px">-->
<!--                    <el-checkbox v-model="bol" style="display: inline-block"></el-checkbox>-->
<!--                    <span data-v-e7e518dc="" class="aggrement">-->
<!--												我同意黄骅市产业发展服务平台使用我所提交的信息用于快捷登录或者创建账号。查看-->
<!--												<span data-v-e7e518dc="">-->
<!--													《用户服务协议》-->
<!--												</span>-->
<!--												与-->
<!--												<span data-v-e7e518dc="">-->
<!--													《个人信息保护政策》-->
<!--												</span>-->
<!--											</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <button data-v-e7e518dc="" @click="login" type="button" class="el-button login_btn el-button&#45;&#45;primary">-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  &lt;!&ndash;&ndash;&gt;-->
<!--                  <span>-->
<!--									登录-->
<!--								</span>-->
<!--                </button>-->
<!--                <div data-v-e7e518dc="" class="divider_wrap el-divider el-divider&#45;&#45;horizontal">-->
<!--                  <div data-v-e7e518dc="" class="el-divider__text is-center" @click="zhuce1">-->
<!--                    注册-->
<!--                  </div>-->
<!--                </div>-->
<!--              </form>-->
<!--              <div v-else>-->
<!--                <img style="width: 300px;height: 300px" src="">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div data-v-e7e518dc="" role="tabpanel" aria-hidden="true" id="pane-enterprise"-->
<!--                 aria-labelledby="tab-enterprise" class="el-tab-pane" style="display: none;">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div  class="loginall">
      <div class="loginl">
        <div class="loginh1">为企业赋能 · 筑数字黄骅</div>
        <div>渤海新区黄骅市产业发展服务平台</div>
      </div>
      <div class="logins">
        <div class="ltitle">《系统管理中心》账号登录</div>
        <div class="loginscont">
          <div style="margin-bottom: 8px">
            <input type="text" v-model="tenant_id" autocomplete="off" placeholder="请输入统一社会信用代码" class="el-input__inner">
          </div>
          <div>
            <input type="text" v-model="phone" autocomplete="off" placeholder="请输入您的账号" class="el-input__inner">
          </div>
          <div class="loginput">
            <input type="password" v-model="password" autocomplete="off" placeholder="请输入登录密码" class="el-input__inner">
            <div class="remberKey">
                <el-checkbox v-model="checked" @change="getKey(tenant_id,phone,password)">记住密码</el-checkbox>
                <span class="forgetKey" @click="forgetKey()">忘记密码</span>
            </div>
          </div>
          <div style="text-align: center">
            <div class="loginbtn" @click="login">登录</div>
            <div>
              <span @click="zhuce1" style="cursor: pointer;">注册</span>
            </div>
          </div>
          <div class="xieye">
            <el-checkbox v-model="bol" style="display: inline-block"></el-checkbox>
            <span data-v-e7e518dc="" class="aggrement">
                我同意黄骅市产业发展服务平台使用我所提交的信息用于快捷登录或者创建账号。查看
                <span class="color3766CF" @click="openpop('userServiceAgreement','用户服务协议')">
                  《用户服务协议》
                </span>
                与
                <span class="color3766CF" @click="openpop('privacyPolicy','个人信息保护政策')">
                  《个人信息保护政策》
                </span>
              </span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="title"
        :visible.sync="centerDialogVisible"
        width="50%"
        center>
      <div style="min-height: 500px;max-height: 500px;overflow: auto;" v-html="datas[opentype]"></div>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">已了解</el-button>
     </span>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import md5 from 'js-md5';
import {queryUrlParams} from '../../utils'
import config from '../../utils/config'
import {sendMsg} from '../../utils/message'

export default {
  data() {
    return {
      title:'',
      centerDialogVisible:false,
      poster:import('../../assets/banner/bgindex.png'),
      bol:false,
      phone:'',
      password:'',
      password1:'',
      creditCode:'',
      tenant_id:'',
      email:'',
      tab:1,
      iszhuce:false,
      ewm:true,
      checked: false,
      newWin:'',
      datas:'',
      opentype:''
    };
  },
  components:{
  },
  computed: {
    transformSize: function () {
      return `translateX(${this.tab==0?0:200}px)`;
    }
  },
  created() {
    let queryUrlP=queryUrlParams()
    this.phone=this.common.getSessionStorage('phone')||'';
    this.password=this.common.getSessionStorage('password')||'';
    this.tenant_id=this.common.getSessionStorage('tenant_id')||'';
    this.loadinit()
    if(queryUrlP.code){
      this.loginto()
    }else if(queryUrlP.error){
      sendMsg({
        type:'fail',
        msg:queryUrlP.error
      })
      this.$message.error(decodeURIComponent(queryUrlP.error));
    }

    // 页面加载时检查是否记住了密码
    const expireAt = this.common.getSessionStorage('expireAt');
    if (expireAt && Date.now() < Number(expireAt)) {
      this.tenant_id = this.common.getSessionStorage('Tenant-Id');
      this.phone = this.common.getSessionStorage('Phone');
      this.password = this.common.getSessionStorage('PassWord');
    }
  },
  methods: {
    openpop(type,title){
      this.title=title
      this.opentype=type
      this.centerDialogVisible=true
    },
    zhuce(){
      this.iszhuce=!this.iszhuce
    },
    zhuce1(){
      this.$router.push({
        path:'/zhuce'
      })
    },
    loadinit(){
      this.$api.index.logininit().then((res)=>{
          this.datas=res.data
      })
    },
    loginto(){
      this.$api.index.enterLogin({
        code:queryUrlParams().code,
        redirectUrl:config.pchref
      }).then((res)=>{
        Cookies.set('saber-access-token', res.data.access_token)
        Cookies.set('Tenant-Id', res.data.tenant_id)
        this.common.addSessionStorage('userInfo',res.data)
        sendMsg({
          type:'success',
          msg:res
        })
        this.$router.replace({
          path:'/home'
        })
      },(res)=>{
        sendMsg({
          type:'fail',
          msg:res
        })
      })
    },
    login(){
      if(!this.bol){
        this.$alert('请同意用户协议和个人信息保护政策', '', {
          confirmButtonText: '同意并登录',
          callback: action => {
            if(action=='confirm'){
              this.bol=true
              this.login()
            }
          }
        });
        return
      }
      if(!this.phone||!this.password||!this.tenant_id){
        this.$message.error('账号或密码不能为空');
        return
      }
      this.common.addSessionStorage('phone', this.phone);
      this.common.addSessionStorage('password', this.password);
      this.common.addSessionStorage('tenant_id', this.tenant_id);
      window.location.href = config.getSsoUrl({
        username: this.phone,
        password: this.password,
        tenant_id: this.tenant_id,
      })
    },

    //记住密码
    getKey(tenant_id,phone,password){

      if(this.checked){
        var currentTime = new Date();
        // 设置密码存活的时间为30天
        currentTime.setDate(currentTime.getDate() + 30);

        this.common.addSessionStorage('Tenant-Id', tenant_id);
        this.common.addSessionStorage('Phone', phone);
        this.common.addSessionStorage('PassWord', password);
      }else{

      }
    },

    //忘记密码
    forgetKey(){
       this.$router.replace({
          path:'/findmima'
        })
    },

    login1(){
      this.$api.index.personLogin({
        account:this.phone,
        password:md5(this.password),
      }).then((res)=>{
        res.data.isperson=true
        // Cookies.set('saber-access-token', res.data.access_token)
        this.common.addSessionStorage('userInfo',res.data)
        this.$router.replace({
          path:'/home'
        })
      })
    },
    zclogin(){
      if(this.password1!=this.password){
        this.$message.error('两次密码输入的不一致!');
        return
      }
      this.$api.index.personRegister({
        phone:this.phone,
        usci:this.creditCode,
        email:this.email,
        password:this.password
      }).then((res)=>{
        res.data.isperson=true
        this.common.addSessionStorage('userInfo',res.data)
        this.$message({
          message: '注册成功',
          type: 'success'
        });
        this.$router.replace({
          path:'/home'
        })
      })
    },
    handleClick(tab, event){

    },
    tabclick(num){
      this.tab=num
    },
    findmima(){
      this.$router.push({
        path:'/findmima'
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.loginall{
  width: 1300px;
  position: absolute;
  overflow: hidden;
}
.loginl{
  width: 414px;
  color: #5B5B5B;
  font-size: 22px;
  float: left;
  margin-top: 112px;
  text-align: center;
  margin-left: 99px;
  .loginh1{
    font-size: 32px;
    line-height: 60px;
    font-weight: bolder;
  }
}
.logins{
  width: 400px;
  background:#fff;
  border-radius: 8px;
  margin-left: 300px;
  float: right;
  .ltitle{
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
    padding-top: 54px;
  }
  .loginscont{
    margin: 36px 34px;
  }
  .loginput{
    margin: 8px 0;
    .remberKey {
      margin-top: 6px;
      font-size: 12px !important;
      .forgetKey {
        float: right;
        cursor: pointer;
        &:hover {
          color:#409eff
        }
      }
    }
  }
  .el-input__inner{
  }
  .el-input__inner::placeholder{
  }
  .xieye{
    font-size: 12px;
    line-height: 25px;
    margin-top: 6px;
  }
  .color3766CF{
    color: #3766CF;
    cursor: pointer;
  }
  .loginbtn{
    width: 100%;
    background: #3766CF;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    line-height: 35px;
    margin: 39px 0 16px 0;
    cursor: pointer;
  }
}
.findmima{
  margin-right: 30px;
  text-align: right;
  font-size: 12px;
  padding: 10px 0;
  color: #074497;
  cursor: pointer;
}
.c-login-202208191618 {
  --input-height: 50px;
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  min-height: 600px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-size: 1920px 1080px;
  background-position: center bottom;
  background-repeat: no-repeat;
  //background:  url(../../assets/icons/login.png);
}

.c-login-202208191618 .login-container {
  width:438px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(../../assets/icons/loginbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.c-login-202208191618 .login-container .left-pic {
  width: 52px;
  height: 55px;
  margin: 25px auto 6px;
  background: url("../../assets/icons/tubiao.png");
  background-size: cover;
  background-repeat: no-repeat
}

.c-login-202208191618 .login-container .tip {
  font-size: 22px;
  color: #0078F2;
  font-weight: 500
}

.c-login-202208191618 .login-container .login_body {
  border-radius: 10px;
  background: #F2F7FB;
  box-shadow: 2px -1px 37px rgba(184,184,184,.26);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  padding: 20px 20px;
  margin: 36px 12px;
}

.c-login-202208191618 .login-container .login_body .login-form {
  margin-top: 20px
}

.c-login-202208191618 .login-container .login_body .login-form .code_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.c-login-202208191618 .login-container .login_body .login-form .code_wrap .code_btn {
  width: 40%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #5a9cf8;
  border: 1px solid #5A9CF8
}

.c-login-202208191618 .login-container .login_body .login-form .login_btn {
  width: 100%;
  display: block;
  background: #074497;
  border-radius: 4px
}

.c-login-202208191618 .login-container .login_body .login-form .aggrement {
  font-size: 12px;
  line-height: 18px;
  color: #303133
}

.c-login-202208191618 .login-container .login_body .login-form .aggrement span {
  color: #0070cc;
  cursor: pointer
}

.c-login-202208191618 .login-container .login_body .login-form .el-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.c-login-202208191618 .login-container .login_body .login-form .el-checkbox,.c-login-202208191618 .login-container .login_body .login-form .el-checkbox .el-checkbox__input {
  white-space: normal
}

.c-login-202208191618 .login-container .login_body .login-form .el-checkbox .el-checkbox__label {
  margin-top: -2px
}

.c-login-202208191618 .login-container .login_body .el-tabs__header .el-tabs__nav {
  width: 100%
}

.c-login-202208191618 .login-container .login_body .el-tabs__header .el-tabs__nav .el-tabs__active-bar {
  width: 100%!important
}

.c-login-202208191618 .login-container .login_body .el-tabs__header .el-tabs__nav .el-tabs__item {
  text-align: center;
  width: 100%!important;
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  padding: 0!important;
}

.c-login-202208191618 .login-container .login_body .divider_wrap {
  margin: 47px 0 35px
}

.c-login-202208191618 .login-container .login_body .divider_wrap .el-divider__text {
  background-color: #edf1f4
}

.c-login-202208191618 .login-container .login_body .other_login {
  width: 70%;
  display: block;
  margin: auto;
  border-color: #409eff;
  color: #409eff
}
</style>
